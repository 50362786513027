<template>
  <div class="add_box">
    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">基本信息</div>
      </div>
      <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" size="mini">
        <div class="check_box">
          <el-form-item label="企业名称" prop="compName" :rules="addForm.compName? [{required: false}] : rules.compName ">
            <el-input v-model.trim="addForm.compName" :disabled="type != 'add' " ></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="compAddress" :rules="addForm.compAddress? [{required: false}] : rules.compAddress ">
            <el-input v-model.trim="addForm.compAddress" :disabled="type != 'add' " ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="compContactUser">
            <el-input v-model.trim="addForm.compContactUser" :disabled="type == 'details' "></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="compContactPhone">
            <el-input v-model.trim="addForm.compContactPhone" :disabled="type == 'details' " oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">账号管理</div>
      </div>

      <div class="account_number" v-for="(item,index) in loginList" :key="index">
        <div class="left_box">
          <div class="left_title">
            <span class="sign">*</span>
            <span>登录手机号（账号）</span>
          </div>
          <el-input size="mini" v-model.trim="item.mobile" @blur="inputBlur(item, index)" :disabled="item.disabled" oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
        </div>
        <div class="addTo" :class="{isVilidateMobile: 'disabled'}" v-if="type != 'details'" @click="addData">+</div>
        <div class="addTo" v-if="type != 'details'" @click="deleteEvent(item.mobile,index)">x</div>
      </div>
    </div>

    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">照片</div>
      </div>

      <div class="img_box">
        <div class="img_content">
          <el-upload class="avatar-uploader" :file-list="licenseImgUrl" accept=".jpg,.png,.jpeg" action="/api/wx/ocr/business/license" list-type="picture-card" :limit="1" :disabled="hide" :class="{disabled:license.uploadDisabled}" :headers="headers" :on-change="(val,file) => handleLimit(val,file,'1')" :on-success="(val,file) =>handleSuccess(val,file,'1')" :on-preview="handlePictureCardPreview" :on-remove="(val,file) =>handleRemove(val,file,'1')">
            <i slot="default" class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span>营业执照</span>
        </div>

        <div class="img_content">
          <el-upload class="avatar-uploader" :file-list="legalIdFrontImg" accept=".jpg,.png,.jpeg" action="/api/baidu/ocr/v1/user/front" list-type="picture-card" :limit="1" :disabled="hide" :class="{disabled:userFront.uploadDisabled}" :headers="headers" :on-change="(val,file) => handleLimit(val,file,'2')" :on-success="(val,file) =>handleSuccess(val,file,'2')" :on-preview="handlePictureCardPreview" :on-remove="(val,file) =>handleRemove(val,file,'2')">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span>法人身份证正面</span>
        </div>

        <div class="img_content">
          <el-upload class="avatar-uploader" :file-list="legalIdBackImg" accept=".jpg,.png,.jpeg" action="/api/baidu/ocr/v1/user/back" list-type="picture-card" :limit="1" :disabled="hide" :class="{disabled:userBack.uploadDisabled}" :headers="headers" :on-change="(val,file) => handleLimit(val,file,'3')" :on-success="(val,file) =>handleSuccess(val,file,'3')" :on-preview="handlePictureCardPreview" :on-remove="(val,file) =>handleRemove(val,file,'3')">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span>法人身份证反面</span>
        </div>
      </div>
    </div>

    <div class="query_btn">
      <el-button type="reset" size="mini" @click="cancel">取 消</el-button>
      <el-button type="button" v-if="type == 'add' " size="mini" @click="submitForm('addForm')">保 存</el-button>
      <el-button type="button" v-if="type == 'edit' " size="mini" @click="submitForm('addForm')">保 存</el-button>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {
  addCarrier,
  userWhite,
  companyUpdate,
  removeUserWhite,
  mobileVerify,
} from "@/api/basics";
import utils from "@/plugin/utils/utils";
export default {
  data() {
    return {
      isVilidateMobile: true, //手机号校验是否通过
      addForm: {},
      rules: {
        compName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        compAddress: [
          { required: true, message: "请输入地址", trigger: "blur" },
        ],
        compContactUser: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        compContactPhone: [
          { required: true, message: "请输入联系人手机号", trigger: "blur" },
        ],
      },
      headers: {
        Authorization: "",
        source: "community",
      }, // 上传照片请求头
      license: {},
      userFront: {},
      userBack: {},
      loginList: [
        {
          mobile: "",
        },
      ],
      licenseImgUrl: [],
      legalIdBackImg: [],
      legalIdFrontImg: [],
      imgShow: false,
      type: this.$route.query.type,
      dialogVisible: false,
      dialogImageUrl: "",
      hide: false,
      addMobile: "",
    };
  },
  mounted() {
    this.headers.Authorization = localStorage.getItem("token");
    let type = this.type;
    console.log(type, "type");
    if (type == "edit") {
      this.details();
      this.hide = false;
    } else if (type == "details") {
      this.details();
      this.hide = true;
    }
  },
  methods: {
    details() {
      let query = JSON.parse(this.$route.query.row);
      console.log(query,'query');
      this.addForm = query;
      if (query.licenseImgUrl) {
        this.licenseImgUrl = [{ url: query.licenseImgUrl }];
        this.handleLimit(query.licenseImgUrl, this.licenseImgUrl, "1");
      }

      if (query.legalIdFrontImg) {
        this.legalIdFrontImg = [{ url: query.legalIdFrontImg }];
        this.handleLimit(query.legalIdFrontImg, this.legalIdFrontImg, "2");
        // this.handleLimit(query.legalIdBackImg, this.legalIdBackImg, "2");
      }

      if (query.legalIdBackImg) {
        this.legalIdBackImg = [{ url: query.legalIdBackImg }];
        this.handleLimit(query.legalIdBackImg, this.legalIdBackImg, "3");
        // this.handleLimit(query.legalIdFrontImg, this.legalIdFrontImg, "3");
      }
       
      this.getAccount();
    },

    async getAccount() {
      let query = JSON.parse(this.$route.query.row);
      let data = {
        compId: query.id,
        pageSize: 10,
        pageNum: 1,
      };
      try {
        let res = await userWhite(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          let items = res.rspBody.items;
          items.forEach((val) => {
            if (this.type == "edit" || this.type == "details") {
              val.disabled = true;
            }
          });
          console.log(items, "itemsitems");
          this.loginList = items;
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    submitForm(formName) {
      //先校验手机号
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type == "add") {
            this.verification();
          } else {
            // this.getUpdate();
            this.verification();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //新增保存
    async preservation() {
      console.log(this.loginList, "loginList");
      var telephone = [];
      if (this.loginList.length == 1) {
        if (this.loginList[0].mobile == "") {
          this.$message({
            type: "warning",
            message: "登录手机号不能为空",
          });
          return;
        }
      }

      var isVilidateMobile = this.isVilidateMobile;
      if (!isVilidateMobile) {
  
        return;
      }
      this.loginList.map((item) => {
        if (item.mobile) {
          telephone.push(item.mobile);
        }
      });
      try {
        let data = {
          compName: this.addForm.compName,
          taxNo: this.license.taxNo,
          compType: this.license.type,
          legalIdNo: this.userFront.idNo,
          legalPhone: "",
          registeredCapital: this.license.registeredCapital,
          businessScope: this.license.businessScope,
          licenseImgUrl: this.license.image,
          legalIdFrontImg: this.userFront.image,
          legalIdBackImg: this.userBack.image,
          legalRepresentative: this.license.legalRepresentative,
          compAddress: this.addForm.compAddress,
          compContactUser: this.addForm.compContactUser,
          compContactPhone: this.addForm.compContactPhone,
          registeredDate: this.license.registeredDate,
          // status: this.license.status,
          status: "0",
          mobiles: telephone,
        };
        console.log(data, "datadata");
        let params = utils.removeNull(data);
        let res = await addCarrier(params);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          this.$message({
            type: "success",
            message: "新增成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    //编辑保存
    async getUpdate() {
      var that = this;
      var isVilidateMobile = this.isVilidateMobile;
      if (!isVilidateMobile) {
      
        return;
      }
      try {
        var telephone = [];
        let obj = { ...this.addForm };
        console.log(obj, "objobjobj",this.license);
        let license = this.license;
        let data = {
          id: obj.id,
          compName: obj.compName,
          taxNo: obj.taxNo?obj.taxNo: license.taxNo,
          compType: obj.compType?obj.compType:license.type,
          legalIdNo: that.userFront.idNo,
          legalPhone: "",
          registeredCapital: obj.registeredCapital?obj.registeredCapital:license.registeredCapital,
          businessScope: obj.businessScope?obj.businessScope:license.businessScope,
          legalRepresentative: obj.legalRepresentative?obj.legalRepresentative:license.legalRepresentative,
          compAddress: obj.compAddress,
          compContactUser: obj.compContactUser,
          compContactPhone: obj.compContactPhone,
          registeredDate: obj.registeredDate?obj.registeredDate:license.registeredDate,
          // status: this.license.status,
          status: "0",
        };
        that.loginList.map((item) => {
          if (item.mobile) {
            telephone.push(item.mobile);
          }
        });
        data.mobiles = telephone;

        if (that.license.image) {
          data.licenseImgUrl = that.license.image;
        } else {
          data.licenseImgUrl = that.addForm.licenseImgUrl;
        }

        if (that.userBack.image) {
          data.legalIdBackImg = that.userBack.image;
        } else {
          data.legalIdBackImg = that.addForm.legalIdBackImg;
        }

        if (that.userFront.image) {
          data.legalIdFrontImg = that.userFront.image;
        } else {
          data.legalIdFrontImg = that.addForm.legalIdFrontImg;
        }
        console.log(data, "data");

        // let params = utils.removeNull(data);
        let res = await companyUpdate(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          this.$message({
            type: "success",
            message: "编辑成功",
          });
          this.$router.push({
            path: "/carrier/carrier",
          });
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },
    //判断该数组中是否有重复项
    isServalMobile() {
      var loginList = this.loginList;
      var hash = {};
      for(let i = 0;i < loginList.length; i++){
        if (!hash[loginList[i].mobile]) {
          hash[loginList[i].mobile]  = true;
        }
      }
      if(Object.keys(hash).length < loginList.length){
        this.isVilidateMobile = false;
        return true
      }else{
        return false
      }
    },

    //登录手机号新增一条数据
    addData() {
      if (!this.isVilidateMobile) return
      let index = this.loginList.length - 1;
      let item = JSON.parse(JSON.stringify(this.loginList[index]));
      console.log(item, "item");
      item.mobile = "";
      item.disabled = false;
      this.loginList.push(item);
      this.isVilidateMobile = false;
    },

    //新增登录手机号的时候检验是否有重复新增
    async  inputBlur(item) {
      var val = item.mobile;
      console.log(val);
      if (!val) {
        this.$message({
          type: "warning",
          message: "请输入手机号",
        });
        return;
      }
      //判断是否重复
      var isSimpleMobile = this.isServalMobile();
      if (isSimpleMobile) {
         this.$message({
          type: "warning",
          message: "该手机号已存在",
        });
        return;
      }
      this.addMobile = val;
      this.verificationMobile();
    },

    async verificationMobile() {
      let data = {
        mobile: this.addMobile,
      };
      try {
        let res = await mobileVerify(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          if (res.rspBody != 1) {
            this.$message({
              type: "warning",
              message: "手机号已存在",
            });
            this.isVilidateMobile = false;
          }else {
            this.isVilidateMobile = true;
          }
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    async verification() {
      let data = {
        mobile: this.addMobile,
      };
      try {
        let res = await mobileVerify(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          if (res.rspBody != 1) {
            this.$message({
              type: "warning",
              message: "手机号已存在",
            });
          }else {
            if (this.type == "add") {
               this.preservation();
            } else {
              this.getUpdate();
            }
           
            // this.verification();
          }
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    //删除登录账号
    async deleteEvent(mobile, index) {
      try {
        console.log();
        if (this.loginList.length == 1) {
          this.$message({
            type: "warning",
            message: "最少要有一个登录账号存在",
          });
          return;
        }
        
        if (mobile == this.addMobile) {
          this.isVilidateMobile = true;
        } else {
          this.verificationMobile();
        }
        if (this.type == "add") {
          this.loginList.splice(index, 1);
        } else {
          let data = {
            mobile: mobile,
          };
          let res = await removeUserWhite(data);
          if (res.retCode == "0000000") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.loginList.splice(index, 1);

          } else {
            this.$message({
              type: "error",
              message: res.retDesc,
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    //取消
    cancel() {
      this.$router.push({
        path: "/carrier/carrier",
      });
    },

    //图片上传
    handleSuccess(res, file, index) {
      console.log(res, file, index, "index");
      if (res.retCode == "0000000") {
        if (index == 1) {
          var rspBody = file.response.rspBody;
          // this.addForm.compName = rspBody.enterpriseName;
          // this.addForm.compAddress = rspBody.address;
          this.$set(this.addForm, "compName",  rspBody.enterpriseName);
          this.$set(this.addForm, "compAddress",  rspBody.address);
          this.license = rspBody;
        } else if (index == 2) {
          this.userFront = file.response.rspBody;
        } else {
          this.userBack = file.response.rspBody;
        }
      } else {
        this.$message({
          type: "warning",
          message: res.retDesc,
        });
      }
    },

    handleLimit(file, fileList, index) {
      console.log(file, fileList, index, "handleLimit");
      if (fileList.length >= 1) {
        if (index == 1) {
          this.license.uploadDisabled = true;
          this.$set(this.license, "uploadDisabled", true);
        } else if (index == 2) {
          this.userFront.uploadDisabled = true;
          this.$set(this.userFront, "uploadDisabled", true);
        } else {
          this.userBack.uploadDisabled = true;
          this.$set(this.userBack, "uploadDisabled", true);
        }
      } else {
        if (index == 1) {
          this.license.uploadDisabled = false;
          this.$set(this.license, "uploadDisabled", false);
        } else if (index == 2) {
          this.userFront.uploadDisabled = false;
          this.$set(this.userFront, "uploadDisabled", false);
        } else {
          this.userBack.uploadDisabled = false;
          this.$set(this.userBack, "uploadDisabled", false);
        }
      }
    },

    //图片上传删除操作
    handleRemove(file, fileList, index) {
      console.log(file, fileList, index);
      if (index == 1) {
        this.license.uploadDisabled = false;
        this.license.image = ""
        this.addForm.licenseImgUrl = "";

      } else if (index == 2) {
        this.userFront.uploadDisabled = false;
        this.userFront.image = "";
        this.addForm.legalIdFrontImg = "";

      } else {
        this.userBack.uploadDisabled = false;
        this.userBack.image = "";
        this.addForm.legalIdBackImg = "";

      }
      this.$forceUpdate();
    },

    handlePictureCardPreview(file) {
      console.log(file, "handlePictureCardPreview");
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.add_box {
  .check_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .account_number {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .left_box {
      display: flex;
      align-content: center;

      .left_title {
        min-width: 130px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .sign {
          color: #f56c6c;
          padding-right: 5px;
        }
      }
    }

    .addTo {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 1px solid #d8d8d8;
      margin-left: 15px;
      line-height: 25px;
      color: #c9c9c9;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .img_box {
    display: flex;
    justify-content: flex-start;

    .img_content {
      padding-right: 15px;
    }
  }

  /deep/ .el-dialog__header {
    display: none;
  }
}
</style>